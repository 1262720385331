import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import imgHero from "../../assets/image/pear-dashboard-crop.png";
import imgCR from "../../assets/image/conference/png/content-2-round.png";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section pt-lg-15 pb-13 pb-lg-24">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col sm="10" lg="5">
              <div className="content-text pt-11 pt-lg-0 pl-xl-7">
                <h2 className="gr-text-4 mb-7">
                  Add-to-cart is just the beginning
                </h2>
                <p className="gr-text-8 mb-0">
                  By maintaining direct relationships with retailers, we're able
                  to provide unprecedented access to real-time purchase data.
                  Now you can make your campaigns{" "}
                  <span class="font-weight-bold text-red">
                    {" "}
                    10x more efficient
                  </span>{" "}
                  with conversion-optimization, run A/B tests, power dynamic
                  content decisions, build look-alike audiences, and team up
                  with retailers to directly re-market to your customers!
                </p>
                <Link
                  to="/cpg#CPGContact"
                  className="btn-link with-icon gr-text-blue gr-text-7 font-weight-bold mt-9"
                >
                  Get started{" "}
                  <i className="icon icon-tail-right font-weight-bold"></i>
                </Link>
              </div>
            </Col>
            <Col sm="10" lg="7">
              <div
                className="content-img pl-9 mr-xl-n10"
                data-aos="zoom-in"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                <img
                  src={imgHero}
                  alt=""
                  className="mw-100 mt-20 hero-img hilite-xl bg-white p-1 gr-box-shadow-xl"
                />
                <div
                  css={`
                    left: unset;
                    bottom: -55px;
                    right: -40px;
                    width: 25%;
                    position: absolute;
                  `}
                  data-aos="fade-down-left"
                  data-aos-delay="1000"
                  data-aos-duration="1000"
                >
                  <img src={imgCR} alt="" className="w-100" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
