import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import imgC3 from "../../assets/image/conference/png/content-3-img.png";
import imgCD from "../../assets/image/conference/png/fill-dot-red.png";
import imgCR from "../../assets/image/conference/png/content-3-round.png";
import Dry1 from "../../assets/image/dry1.png";
import Dry2 from "../../assets/image/dry2.png";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section content-section-3 py-13 pt-lg-19 pb-lg-19">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col
              xs="6"
              lg="4"
              className="my-16 offset-5 pr-16 offset-lg-2  mb-lg-0"
            >
              <div
                className="content-image position-relative gr-z-index-1"
                css={``}
              >
                <img
                  src={Dry1}
                  alt=""
                  data-aos="zoom-in"
                  data-aos-duration="800"
                  className="w-100 gr-box-shadow-xl hilite-xl"
                  css={``}
                />
                {/* <div className="img-1 gr-abs-tl-custom-4">
                  <img
                    src={imgCD}
                    alt=""
                    data-aos="fade-down"
                    data-aos-duration="800"
                    data-aos-delay="600"
                    className="w-auto"
                  />
                </div> */}
                <div
                  className="img-2 gr-z-index-n1"
                  css={`
                    position: absolute;
                    left: -40%;
                    top: -40%;
                    bottom: 40%;
                    right: 40%;
                  `}
                >
                  <img
                    src={Dry2}
                    alt=""
                    data-aos="fade-up-left"
                    data-aos-duration="800"
                    data-aos-delay="1000"
                    className="w-100 gr-box-shadow-xl hilite-xl"
                  />
                </div>
                <div
                  className=" aos-init aos-animate gr-z-index-n1"
                  data-aos="fade-down-left"
                  data-aos-delay="1000"
                  data-aos-duration="1000"
                  css={`
                    position: absolute;
                    bottom: -39px;
                    left: -75px;
                  `}
                >
                  <img src={imgCR} alt="" class="w-auto" />
                </div>
              </div>
            </Col>
            <Col sm="10" lg="6">
              <div className="content-text pl-lg-10">
                <h2 className="gr-text-4 mb-9">
                  An omnichannel product locator
                </h2>
                <p className="gr-text-8 mb-0">
                  Drive both in-store and e-commerce sales from your website or
                  any passive brand-building content. Our product locator is
                  built on the same full retailer coverage and advanced
                  analytics technology as our shoppable media.
                </p>
                <Link
                  to="/#Contact"
                  className="btn-link with-icon gr-text-blue gr-text-7 font-weight-bold mt-11"
                >
                  Reach out{" "}
                  <i className="icon icon-tail-right font-weight-bold"></i>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
