import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

const Pricing = () => {
  return (
    <div id="CPGPricing" className="pricing-section pt-8 pt-lg-30 pb-5">
      <Container>
        <Row className="justify-content-center">
          <Col lg="9" xl="8">
            <div className="px-md-15 text-center mb-8 mb-lg-16">
              <h2 className="title gr-text-2 mb-9 mb-lg-12">Packages</h2>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg="4" md="6" sm="8" className="col-lg-4 col-md-6 col-sm-8 mb-9">
            <div className="pricing-card gr-hover-shadow-1 text-center pt-9 pb-9 pr-9 pr-xl-9 pl-9 pl-xl-9 bg-gray-2 hilite-xl rounded-10">
              <div className="price-content light-mode-texts">
                <span className="small-title gr-text-16 text-uppercase text-dark font-weight-bold">
                  Shoppable Media
                </span>
                {/* <div className="d-flex align-items-end justify-content-center mt-9 ">
                  <h2 className="price-value gr-text-2 text-dark font-weight-bold line-spacing-none mb-0">
                    Shoppable Media
                  </h2>
                </div> */}
                <ul className="card-list list-style-border text-center pl-0 mt-9 mb-3 mx-auto">
                  <li className="text-black gr-text-8 border-top border-gray-3 mb-0 py-7 d-block">
                    Full retailer coverage
                  </li>
                  <li className="text-black gr-text-8 border-top border-gray-3 mb-0 py-7 d-block">
                    Unlimited landing pages
                  </li>
                  <li className="text-black gr-text-8 border-top border-gray-3 mb-0 py-7 d-block">
                    On-site Product Locator widget
                  </li>
                  <li className="text-black gr-text-8 border-top border-gray-3 mb-0 py-7 d-block">
                    --
                  </li>
                  <li className="text-black gr-text-8 border-top border-gray-3 mb-0 py-7 d-block">
                    --
                  </li>
                </ul>
              </div>
              <a href="/cpg#CPGContact">
                <Button
                  variant="dark"
                  className="btn-dark gr-hover-y gr-min-width-219 mx-auto"
                >
                  Get Started
                </Button>
              </a>
            </div>
          </Col>
          <Col lg="4" md="6" sm="8" className="col-lg-4 col-md-6 col-sm-8 mb-9">
            <div className="pricing-card gr-hover-shadow-1 text-center pt-9 pb-9 pr-9 pr-xl-9  pl-9 pl-xl-9 hilite-xl bg-white rounded-10">
              <div className="price-content light-mode-texts">
                <span className="small-title gr-text-16 text-uppercase text-green font-weight-bold">
                  Optimization
                </span>
                {/* <div className="d-flex align-items-end justify-content-center mt-9 ">
                  <span className="currency mr-2 gr-text-6 text-green font-weight-bold line-spacing-none">
                    $
                  </span>
                  <h2 className="price-value gr-text-2 text-green font-weight-bold line-spacing-none mb-0">
                    500
                  </h2>
                  <span className="per gr-text-9 text-green ">/mo</span>
                </div> */}
                <ul className="card-list list-style-border text-center pl-0 mt-9 mb-3 mx-auto">
                  <li className="text-black gr-text-8 border-top border-gray-3 mb-0 py-7 d-block">
                    Full retailer coverage
                  </li>
                  <li className="text-black gr-text-8 border-top border-gray-3 mb-0 py-7 d-block">
                    Unlimited landing pages
                  </li>
                  <li className="text-black gr-text-8 border-top border-gray-3 mb-0 py-7 d-block">
                    On-site Product Locator widget
                  </li>
                  <li className="text-black gr-text-8 border-top border-gray-3 mb-0 py-7 d-block">
                    Conversion-optimized campaigns
                  </li>
                  <li className="text-black gr-text-8 border-top border-gray-3 mb-0 py-7 d-block">
                    --
                  </li>
                </ul>
              </div>
              <a href="/cpg#CPGContact">
                <Button variant="green" className="gr-hover-y w-100 mx-auto">
                  Get Started
                </Button>
              </a>
            </div>
          </Col>
          <Col lg="4" md="6" sm="8" className="col-lg-4 col-md-6 col-sm-8 mb-9">
            <div className="pricing-card hilite-xl h-100 gr-hover-shadow-1 text-center pt-9 pb-9 pr-9 pr-xl-9 pl-9 pl-xl-9 bg-blackish-blue rounded-10 dark-mode-texts d-lg-flex flex-column">
              <div className="price-content">
                <span className="small-title gr-text-16 text-uppercase text-yellow font-weight-bold d-block">
                  Full Transparency
                </span>
                {/* <div className="d-flex align-items-end justify-content-center mt-9 ">
                  <span className=" gr-opacity-5 mr-2 gr-text-9 gr-text-color">
                    from
                  </span>
                  <span className="currency mr-2 gr-text-6 text-yellow font-weight-bold line-spacing-none">
                    $
                  </span>
                  <h2 className="price-value gr-text-2 text-yellow font-weight-bold line-spacing-none mb-0">
                    0.02
                  </h2>
                  <span className="ml-1 per gr-text-9 text-yellow ">
                    /visit
                  </span>
                </div> */}

                <ul className="card-list list-style-border text-center pl-0 mt-9 mb-3 mx-auto">
                  <li className="gr-text-color gr-text-8 border-top border-gray-opacity mb-0 py-7 d-block">
                    Custom retailer integrations
                  </li>
                  <li className="gr-text-color gr-text-8 border-top border-gray-opacity mb-0 py-7 d-block">
                    Unlimited landing pages
                  </li>
                  <li className="gr-text-color gr-text-8 border-top border-gray-opacity mb-0 py-7 d-block">
                    On-site Product Locator widget
                  </li>
                  <li className="gr-text-color gr-text-8 border-top border-gray-opacity mb-0 py-7 d-block">
                    Conversion-optimized campaigns
                  </li>
                  <li className="gr-text-color gr-text-8 border-top border-gray-opacity mb-0 py-7 d-block">
                    Real-time purchase data
                  </li>
                </ul>
              </div>
              <a href="/cpg#CPGContact">
                <Button
                  variant="danger"
                  className="gr-hover-y gr-min-width-219 text-white mx-auto mt-auto"
                >
                  Contact Sales
                </Button>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Pricing;
