import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";

const Content = () => {
  const offerRotation = [
    [1699976141042688, 0],
    [1563386129195808, 0],
    [1709288898975169, 0],
    [1699994383725568, 0],
    [1845090087718912, 0]
  ];
  const offerIdx = Math.floor(Math.random() * offerRotation.length);
  console.log(offerIdx);
  const offerForce = offerRotation[offerIdx];
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col className="pb-10">
              <div className="content-text">
                <h2 className="gr-text-4 mb-8">Make All Media Shoppable</h2>
                <p className="gr-text-8 pr-4 mb-0">
                  Now you can afford to meet the customer wherever they are in
                  the purchase funnel. Whether running a small test or
                  transforming hundreds of campaigns, we only charge when
                  consumers actively use our products!
                </p>
                <Link
                  to="/cpg#CPGContact"
                  className="btn-link with-icon gr-text-blue gr-text-7 font-weight-bold mt-9"
                >
                  Get started{" "}
                  <i className="icon icon-tail-right font-weight-bold"></i>
                </Link>
              </div>
            </Col>
            <Col sm="10" lg="5" className="offset-lg-1 mt-10 mt-lg-0 py-10">
              <div
                className="col-auto justify-content-center"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-delay="500"
              >
                <div
                  className="embed-responsive bg-gradient-1 gr-box-shadow-xl hilite-xl my-10"
                  css={`
                    width: 280px !important;
                    height: 615px !important;
                    display: inline-block;
                    margin-bottom: 92px;
                    background-size: cover;
                  `}
                >
                  <iframe
                    title="Where-to-buy demo"
                    src={`https://offers.pearcommerce.com/picker/${offerForce[0]}?force_single=${offerForce[1]}`}
                    className="embed-responsive-item"
                    css={`
                      -ms-zoom: 0.75;
                      -moz-transform: scale(0.75);
                      -moz-transform-origin: 0 0;
                      -o-transform: scale(0.75);
                      -o-transform-origin: 0 0;
                      -webkit-transform: scale(0.75);
                      -webkit-transform-origin: 0 0;
                      width: 375px !important;
                      height: 820px !important;
                    `}
                  ></iframe>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
