import React from "react";
// import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";


import imgHW from "../../assets/image/svg/l6-hero-wave.svg";
import imgHWD from "../../assets/image/svg/l6-hero-wave-dark.svg";
import imgS1 from "../../assets/image/l6/png/l6-hero-shape1.png";
import imgS2 from "../../assets/image/l6/png/l6-hero-shape2.png";
import imgS3 from "../../assets/image/l6/png/l6-hero-shape3.png";
import imgHero from "../../assets/image/pear-dashboard.png";

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <div
        className="hero-area-03 bg-gradient-5 pt-29 pt-lg-32 pb-5 position-relative text-white"
        css={`
          background-image: linear-gradient(
            225deg,
            #7b11f9 0%,
            #7922d8 39%,
            #3636b2 100%
          );
        `}
      >
        <div
          className="shape-1 gr-abs-tl"
          data-aos="fade-down-right"
          data-aos-duration="500"
          data-aos-delay="500"
        >
          <img src={imgS1} alt="" />
        </div>
        <div
          className="shape-2 gr-abs-tl"
          data-aos="fade-down-right"
          data-aos-duration="800"
          data-aos-delay="800"
        >
          <img src={imgS2} alt="" />
        </div>
        <div
          className="shape-3 gr-abs-tl"
          data-aos="fade-down-right"
          data-aos-duration="1100"
          data-aos-delay="1100"
        >
          <img src={imgS3} alt="" />
        </div>
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col
              sm="10"
              md="9"
              lg="7"
              xl="7"
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-delay="0"
            >
              <div className="hero-content text-center">
                <h1 className="gr-text-2 text-white mb-8">
                  <div>Shoppable Media</div>{" "}
                  <div className="gr-text-4 gr-opacity-8">
                    meets
                  </div>{" "}
                  <div>Performance Marketing</div>
                </h1>
                <p className="gr-text-8 text-white">
                  <span className="gr-opacity-7">
                    {" "}
                    Pear is the only where-to-buy with full retail coverage,
                    conversion-optimized campaigns, and{" "}
                  </span>
                  <span className="text-yellow gr-opacity-visible font-weight-bold">
                    {" "}
                    direct access to consumers
                  </span>
                  <span className="gr-opacity-7">.</span>
                </p>

                <div className="hero-btns d-flex justify-content-center mt-11">
                  <a href="/cpg#CPGContact">
                    <button className="text-white with-icon gr-hover-y mb-6 mb-md-0 mr-md-7 btn-yellow btn">
                      Get started!
                      <i className="fas fa-arrow-right gr-text-11"></i>
                    </button>
                  </a>
                </div>
              </div>
            </Col>
            <Col sm="10" className="mt-12 mt-lg-16 ">
              <div
                className="hero-img hilite-xl bg-white p-1 gr-box-shadow-xl"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-delay="0"
              >
                <img className="img-fluid" src={imgHero} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
        <div className="wave-shape">
          <img src={imgHW} alt="" className="w-100 light-shape default-shape" />
          <img src={imgHWD} alt="" className="w-100 dark-shape" />
        </div>
      </div>
    </>
  );
};

export default Hero;
