import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import imgB1 from "../../assets/image/3m-gray.png";
import imgB2 from "../../assets/image/brooks-gray.png";
import imgB3 from "../../assets/image/general-mills-gray.png";
import imgB4 from "../../assets/image/post-gray.png";
import imgB5 from "../../assets/image/johnsonville-gray.png";

const Brands = () => {
  const LogoImg = p => (
    <div
      className="single-brand mx-3 py-6 gr-opacity-8 gr-hover-opacity-full col-4 col-lg-2"
      data-aos="zoom-in-right"
      data-aos-duration="500"
    >
      <img
        {...p}
        alt=""
        css={`
          max-height: 50px;
          max-width: 100%;
          opacity: 0.6;
        `}
      />
    </div>
  );
  return (
    <>
      {/* <!-- Brands Area --> */}
      <div className="brand-section border-bottom pb-11  bg-default-6">
        <Container className="border-top pt-10">
          <Row className="justify-content-center align-items-center">
            <Col md="8">
              <p className="gr-text-9 gr-opacity-7 text-center mb-7">
                Trusted by brands of all sizes across every vertical
              </p>
            </Col>
            <Col xs="12">
              <div className="brand-logos d-flex justify-content-center justify-content-xl-between align-items-center mx-n9 flex-wrap row">
                <LogoImg src={imgB1} className="py-2" />
                <div
                  className="single-brand mx-3 py-6 gr-opacity-8 gr-hover-opacity-full col-3 col-lg-2"
                  data-aos="zoom-in-right"
                  data-aos-duration="500"
                >
                  <img
                    src={imgB2}
                    alt=""
                    css={`
                      max-height: 55px;
                      max-width: 100%;
                      opacity: 0.6;
                      max-width: 130%;
                      right: 10%;
                      display: block !important;
                      position: absolute;
                      bottom: -3%;
                    `}
                  />
                </div>

                <LogoImg src={imgB5} className="mb-1" />
                <LogoImg src={imgB4} className="py-1" />

                <LogoImg src={imgB3} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Brands;
