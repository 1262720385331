import React from "react";
import PageWrapper from "../components/PageWrapper";
import Process from "../sections/pear-landing/Process";

import ExampleDash from "../sections/pear-landing/Example-Dash";
import RetailCoverage from "../sections/pear-landing/RetailCoverage";

import HeroPurple from "../sections/pear-landing/Hero-Purple";
import ExampleWTB from "../sections/pear-landing/Example-WTB";

/* eslint-disable no-unused-vars */
import Content1 from "../sections/landing4/Content1";
import Fact from "../sections/landing4/Fact";
import Content2 from "../sections/landing4/Content2";
import Testimonial from "../sections/landing4/Testimonial";
import Faq from "../sections/landing4/Faq";
import CTA from "../sections/landing4/CTA";
import Pricing from "../sections/pear-landing/Pricing";
import CPGContent1 from "../sections/pear-landing/CPGContent1";
import CPGContent2 from "../sections/pear-landing/CPGContent2";
import Features from "../sections/landing4/Features";
import Testimonials from "../sections/pear-landing/Testimonials";
import ProductLocator from "../sections/pear-landing/ProductLocator";
/* eslint-enable no-unused-vars */

import Features2 from "../sections/pear-landing/Features";
import Brands from "../sections/pear-landing/Brands";
import Newsletter from "../sections/landing2/Newsletter";
import CPGPricing from "../sections/pear-landing/CPG-Pricing";
import ContactSmall from "../sections/pear-landing/ContactSmall";

const CPG = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          theme: "dark",
          align: "center",
          isFluid: true,
          darkClass: "bg-gradient-4"
        }}
        footerConfig={{
          theme: "light",
          style: "style3" //style1, style2
        }}
      >
        <HeroPurple />
        <Process />
        <Brands />

        <div class="alert-section py-9 bg-secondary dark-mode-texts ">
          <div class="container">
            <div class="justify-content-center row">
              <div class="col-xl-7 col-lg-8 col-md-10 col-sm-9 col-10">
                <div class="alert-content d-flex flex-column flex-lg-row align-items-center text-center justify-content-lg-center">
                  <span class="btn-badge rounded-pill bg-white gr-text-12 text-uppercase font-weight-bold text-primary py-1 px-6 mr-5 mb-6 mb-lg-0 d-inline-flex align-items-center">
                    New
                  </span>
                  <span class="alert-text gr-text-9 text-white">
                    We've just introduced flat-rate pricing.{" "}
                    <a
                      class="action-link text-white gr-text-underline"
                      href="#CPGPricing"
                    >
                      Now you can make all media shoppable!
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-pattern pattern-8 bg-default-3 py-20">
          <RetailCoverage />
          <ExampleWTB />
          <ProductLocator />
          <ExampleDash />
        </div>
        <div className="gradient-sections-wrapper bg-gradient-1">
          {/* <Testimonials /> */}
          {/* <div className="col-12">
            <div className="border-sm-divider dark text-center mt-lg-13"></div>
          </div> */}
          <Features2 />
        </div>
        <div className="gradient-sections-wrapper bg-default-2 bg-pattern pattern-4">
          <CPGPricing />
          <Newsletter />
        </div>
        {/* <CTA /> */}
      </PageWrapper>
    </>
  );
};
export default CPG;
